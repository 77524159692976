
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ContentTitle from "@/components/ContentTitle.vue";
import NoCertification from "@/components/NoCertification.vue";
import PerformanceEcharts from "./component/PerformanceEcharts.vue";
import PwdManage from "./component/PwdManage.vue";
import AddUser from "./component/dialog/AddUser.vue";
import WaitOpen from "./component/WaitOpen.vue";
import PhoneCheckDialog from "./component/PhoneCheckDialog.vue";
import UninstallCheckDialog from "./component/UninstallCheckDialog.vue";
import RenewalDialog from "../home/component/RenewalDialog.vue";    // 续费弹窗
import RenewalUpgradeDialog from "./component/dialog/RenewalUpgradeDialog.vue";
import { debounce } from "@/utils/utils";
import { SERVER_CONFIG, SERVER_CONFIG_MC_FOUR, RESOURCE_CONFIG } from '@/utils/global';
import AppDetail from "./component/AppDetail.vue";
import { fromCode, toCode } from '@/utils/encrypt';

enum PageType {
    ActivePageTypeMain = 1,
    ActivePageTypeHtml = 2,
    ActivePageTypeMoreDevMain = 3,
}

@Component({
    name: "resource",
    components: {
        ContentTitle,
        PerformanceEcharts,
        PwdManage,
        AddUser,
        WaitOpen,
        NoCertification,
        PhoneCheckDialog,
        UninstallCheckDialog,
        RenewalDialog,
        RenewalUpgradeDialog,
        AppDetail,
    },
})
export default class Resource extends Vue {
    private apiProxy = process.env.NODE_ENV === 'development' ? '/apiProxy' : '';
    private activePage: any = 0; // 1，主页面；2，html页面详情
    private pageType: any = PageType;
    private baseUrlIframe = process.env.VUE_APP_URL;
    private contentTitle: string = "云服务器";
    private certStatus: number = 2;
    // url带的userId
    private userId: string = '';
    private helpTitle: string = '';
    private dbId: unknown = null;
    private serverInfo: any = {};
    private serverId: string = '';
    private serverIdPre: string = '';
    private serverList: any = [];
    private paramServer: any = {};
    private mcList: any = [];
    private chartData: any = {
        serverHarddiskNum: 1,
        serverCpuNum: 27,
        serverMemoryNum: 59,
    };
    private loading: boolean = false;
    // 密码管理
    private existPwdManage: boolean = false;
    // 新增用户数
    private existAddUser: boolean = false;
    // 是否已开通
    private opened: boolean = true;
    // 加载iframe
    private iframeLoading: boolean = true;
    private iframeHtml: string = '';
    // 系统还原 安全校验
    private phoneCheckFlag: boolean = false;
    private phone: string = '';
    private mcId: number = 0;
    // 卸载 安全校验
    private uninstallCheckFlag: boolean = false;
    // 应用续费
    private renewalFlag: boolean = false;
    private renewalProdInfo: any = {};
    // 服务器数据库升级、续费
    private renewalUpgradFlag: boolean = false;
    private orderGoodsSceneType: number = RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_UPGRADE;
    // 应用详情
    private detailFlag: boolean = false;
    private currentRow: any = {};
    private goBack() {
        if (this.userId !== '') {
            this.$router
            .push(`/company/reportCustomer`)
            .catch((err) => err);
        } else {
            window.history.back();
        }
    }
    // 开发平台信息
    get moreDevInfo() {
        return this.mcList.find((el: any) => el.parentId === 0);
    }
    // 当前服务器是否过期
    private isServerExpired() {
        return this.paramServer.serverExpired;
    }
    private isFourMCVersion(item: any) {
        return item.mcVersion === '4.0';
    }
    private created() {
        if (this.$route.fullPath.indexOf('userData=') > -1) {
            const userId = this.$route.query.userData + '';
            this.userId = fromCode(userId);
        }
        this.getCompanyServer();
    }
    // 资源购买页跳转
    private gotoResourceBuy() {
        this.$router.push(`/company/resource/resourceBuy?userData=${toCode(this.userId)}`)
        .catch((err) => err);
    }
    private tabChange(value: any) {
        if (value.name !== this.serverIdPre) {
            debounce(() => {
                this.getServer();
                this.serverIdPre = this.serverId;
            }, 200)();
        }
    }
    // 获得个人的 服务器列表
    private  getCompanyServer() {
        this.loading = true;
        const paramUserId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
        this.$httpService.getData({preventRepeat: 1}, '/apiProxy/api/frontend/users/company-server/' + paramUserId)
        .then((res: any) => {
            this.certStatus = this.$store.state.userInfo.certStatus;
            if (this.userId !== '') {
                this.helpTitle = `归属客户：${res.companyName}`;
            }
            this.phone = res.mobile;
            this.serverList = [];
            res.serverInfoList.forEach((item: any) => {
                if (item.serverType === SERVER_CONFIG.SERVER_TYPE
                ||
                item.serverType === SERVER_CONFIG_MC_FOUR.SERVER_TYPE) {
                    item.serverId = item.serverId + '';
                    this.serverList.push(item);
                }
            });
            if (this.serverList.length > 0) {
                this.serverId = this.serverList[0].serverId;
                this.serverIdPre = this.serverList[0].serverId;
                this.getServer();
                this.loading = false;
            } else {
                this.getUserCurrent();
            }
        }).catch(() => {
            this.loading = false;
        });
    }
    // 获取当前用户信息
    private getUserCurrent() {
        const paramData: any = {};
        paramData.notLoading = true;
        paramData.preventRepeat = 1;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/users/current')
        .then((res: any) => {
            this.$store.commit('saveUserInfo', res);
            this.certStatus = res.certStatus;
            if (this.certStatus === 2) {
                this.gotoResourceBuy();
            }
        }).finally(() => {
            this.loading = false;
        });
    }
    // 获得 tab下的  服务器信息
    private getServer() {
        const currentServer = this.serverList.find((item: any) => item.serverId === this.serverId);
        const installStatus = currentServer.installStatus;
        this.paramServer = currentServer;
        // installStatus = 3 是安装成功
        if (installStatus !== 3) {
            this.opened = false;
        } else {
            this.opened = true;
            this.getServerInfo();
            this.getServerLoad();
            this.getMcList();
        }
        if (currentServer.serverType === 4) {
            // 切换4.0云服务器页面
            this.activePage = PageType.ActivePageTypeMoreDevMain;
        } else {
            // 旧版云服务器页面
            this.activePage = PageType.ActivePageTypeMain;
        }
    }
    // 获得服务器信息
    private getServerInfo() {
        const paramData: any = {};
        paramData.serverId = this.serverId;
        paramData.userId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/user/server')
        .then((res: any) => {
            this.serverInfo = res;
        });
    }
    // 获得性能监控信息
    private getServerLoad() {
        const paramData: any = {};
        paramData.serverId = this.serverId;
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/user/server/load')
        .then((res: any) => {
            this.chartData = {
                serverHarddiskNum: res.hardDiskRate || 0,
                serverCpuNum: res.cpuRate || 0,
                serverMemoryNum: res.memoryRate || 0,
            };
        });
    }
    // 获得服务器下的  应用列表
    private getMcList() {
        const paramData: any = {};
        paramData.serverId = this.serverId;
        paramData.userId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
        this.mcList = [];
        this.$httpService.getData(paramData, '/apiProxy/api/frontend/user/server/mcs')
        .then((res: any) => {
            this.mcList = res;
        });
    }
    // 刷新 性能监控 信息
    private refreshConfig() {
        this.getServerLoad();
    }
    private handlePwdManage() {
        this.existPwdManage = true;
    }
    private closeHandlePwdManage(num: number) {
        this.existPwdManage = false;
    }
    private handleAddUser() {
        this.existAddUser = true;
    }
    private closeHandleAddUser(num: number) {
        this.existAddUser = false;
    }
    private switchHtml(htmlVal: number, id = null) {
        this.activePage = PageType.ActivePageTypeHtml;
        if (htmlVal === 1) {
            // 云用户数详情 /v2/user/company/user.htm?onLineStatus=1&serverType=1&serverId=3055
            this.iframeHtml = `/v2/user/company/user.htm?onLineStatus=1&serverType=1&serverId=${this.serverId}&userData=${this.userId}&pageSource=new`;
        } else if (htmlVal === 2) {
            // 性能监控详情 /user/server/performance.htm?serverId=3055&flag=2
            this.iframeHtml = `/user/server/performance.htm?serverId=${this.serverId}&flag=2&userData=${this.userId}&pageSource=new`;
        } else if (htmlVal === 3) {
            // 数据库详情 /user/server/db/dbdetail/8071.htm
            this.dbId = id;
            this.iframeHtml = `/user/server/db/dbdetail/${id}.htm?userData=${this.userId}&pageSource=new`;
        } else if (htmlVal === 4) {
            // 应用详情 /user/server/mc/mcdetail/8117.htm
            this.iframeHtml = `/user/server/mc/mcdetail/${id}.htm?userData=${this.userId}&pageSource=newServer`;
        }
        debounce(() => {
            this.setIframeLoading();
        }, 200)();
    }
    private setIframeLoading() {
        this.iframeLoading = true;
        this.$nextTick(() => {
            // 注意： 如果本身iframe嵌套的盒子有延迟 则下面判断最好也加延迟
            const iframe: any = document.querySelector('#iframe_pass_index');
            // 处理兼容行问题
            if (iframe && iframe.attachEvent) {
                iframe.attachEvent('onload', () => {
                    this.iframeLoading = false;
                });
            } else {
                iframe.onload = () => {
                    this.iframeLoading = false;
                };
            }
        });
    }
    private setRenewalUpgrad(sceneType: number) {
        this.orderGoodsSceneType = sceneType;
        this.renewalUpgradFlag = true;
    }
    private renewalUpgradCloseMsg() {
        this.renewalUpgradFlag = false;
    }
    private gotoServer(typeIndex: number, serverId: number) {
        // 升级 /user/order/buyServer.htm?serverId=2729&payType=2&serverType=1&userId=53415
        // 续费 /user/order/buyServer.htm?serverId=2729&payType=1&serverType=1&userId=53415
        const paramData: any = {};
        paramData.serverId = this.serverId;
        paramData.userId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
        paramData.operationType = 1;
        paramData.payType = typeIndex;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/orders/server/can')
        .then((res: any) => {
            this.setRenewalUpgrad(
                typeIndex === 1 ? RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_RENEW
                                : RESOURCE_CONFIG.SERVER_GOODSSCENETYPE_UPGRADE,
            );
        });
    }
    private gotoDB(typeIndex: number, dbId: number) {
        // 升级 /user/order/buyDb.htm?payType=2&id=3350
        // 续费 /user/order/buyDb.htm?payType=1&id=3350
        const paramData: any = {};
        paramData.serverId = this.serverId;
        paramData.userId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
        paramData.operationType = 2;
        paramData.sdnId = dbId;
        paramData.payType = typeIndex;
        this.dbId = dbId;
        this.$httpService.postData(paramData, '/apiProxy/api/frontend/orders/server/can')
        .then((res: any) => {
            this.setRenewalUpgrad(
                typeIndex === 1 ? RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE_RENEW
                                : RESOURCE_CONFIG.ORACLE_GOODSSCENETYPE_UPGRADE,
            );
        });
    }
    private enterDevelop(item: any, handleType: number) {
        let url = '';
        if (handleType === 1) {
            url = `/v2/user/uc/mc/goFpToMc.htm?mcMask=${item.mcMask}`;
        } else if (handleType === 2) {
            url = `/v2/user/uc/mc/goSysToMc.htm?mcMask=${item.mcMask}`;
        }

        if (item.isSelfBuiltRoom === 1) {
            const msg = `限摩尔元数VPN环境下使用，如无VPN账号请在钉钉OA审批中发起申请（非公司员工请联系生态或项目经理申请）<a style="font-size:12px; color:#999;" href="https://secloud1.ruijie.com.cn/SSLVPNClient">VPN客户端下载地址`;
            this.$confirm(msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                dangerouslyUseHTMLString: true,
                type: 'warning',
            }).then(() => {
                // 确定
                window.open(url);
            }).catch(() => {
                // 取消
            });
        } else {
            window.open(url);
        }
    }
    // 服务器登录
    private openSsh(runStatus: number, isSelfBuiltRoom: number) {
        // -2，已卸载；-1，待部署；1，启动中；2，运行中；3，已停止；4，已过期
        if (runStatus === -1 || runStatus === 1) {
            this.$message.warning('服务器未运行，无法访问，请联系管理员！');
        } else {
            if (isSelfBuiltRoom === 1) {
                const msg = `限摩尔元数VPN环境下使用，如无VPN账号请在钉钉OA审批中发起申请（非公司员工请联系生态或项目经理申请）<a style="font-size:12px; color:#999;" href="https://secloud1.ruijie.com.cn/SSLVPNClient">VPN客户端下载地址`;
                this.$confirm(msg, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    dangerouslyUseHTMLString: true,
                    type: 'warning',
                }).then(() => {
                    // 确定
                    window.open(`/user/server/serverlogin.htm?id=${this.serverId}`);
                }).catch(() => {
                  // 取消
                });
            } else {
                window.open(`/user/server/serverlogin.htm?id=${this.serverId}`);
            }
        }
    }
    // 服务器 启动、停止
    private openStopServer(item: any, handleType: number) {
        // -2，已卸载；-1，待部署；1，启动中；2，运行中；3，已停止；4，已过期
        this.$confirm(`请确定是否${handleType === 0 ? '停止' : '启动'}服务器“${item.serverName}”吗?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(() => {
            // 确定
            this.startStopServer(handleType);
        });
    }
    private startStopServer(handleType: number) {
        const paramData: any = {};
        paramData.serverId = this.serverId;
        paramData.userId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
        this.$httpService
        .getData(paramData, `/apiProxy/api/frontend/user/server/${handleType === 0 ? 'stop' : 'start'}`)
        .then((res: any) => {
            if (handleType === 0) {
                this.$message.success('停止成功');
            } else {
                this.$message.success('启动成功');
            }
            this.getServerInfo();
            this.getServerLoad();
        });
    }
    // 数据库 启动、停止
    private openStopDB(item: any, handleType: number) {
        // -3，已拒绝；-2，已卸载；-1，待部署；0，已停止；1，启动中；2，运行中；3，已过期
        this.$confirm(`请确定是否${handleType === 0 ? '停止' : '启动'}数据库“${item.dbName}”吗?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(() => {
            // 确定
            this.startStopDB(item, handleType);
        });
    }
    private startStopDB(item: any, handleType: number) {
        const paramData: any = {};
        paramData.sdnId = item.dbId;
        paramData.userId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
        this.$httpService
        .getData(paramData, `/apiProxy/api/frontend/user/server/db/${handleType === 0 ? 'stop' : 'start'}`)
        .then((res: any) => {
            if (handleType === 0) {
                this.$message.success('停止成功');
            } else {
                this.$message.success('启动成功');
            }
            this.getMcList();
        });
    }
    // 数据库 登录
    private openDBSsh(runStatus: number, dbId: number) {
        // -3，已拒绝；-2，已卸载；-1，待部署；0，已停止；1，启动中；2，运行中；3，已过期
        if (runStatus === -1 || runStatus === 1) {
            this.$message.warning('数据库未运行，无法访问，请联系管理员！');
        } else {
            window.open(`/user/server/databaselogin.htm?id=${dbId}`);
        }
    }
    // 应用 系统还原
    private handleSysRestore(item: any) {
        if (item.processStatus === 1 || item.processStatus === 3) {
            this.$message.warning('系统还原申请处理中，请勿重复提交！');
            return;
        }
        const msg = `系统还原操作将会清除该系统的所有数据，并还原至该系统的最新版本。该操作无法恢复，您确认要申请吗?`;
        this.$confirm(msg, '系统还原', {
            confirmButtonText: '确认申请',
            cancelButtonText: '取消',
        }).then(() => {
            // 确定
            this.phoneCheckFlag = true;
            this.mcId = item.mcId;
        }).catch(() => {
            // 取消
        });
    }
    private closeHandlePhoneCheck(num: number) {
        this.phoneCheckFlag = false;
        if (num === 3) {
            // 刷新一下 应用列表
            this.getMcList();
        }
    }
    // 应用 卸载
    private handleUninstall(item: any) {
        // -3，已拒绝；-2，已卸载；-1，待部署；0，已停止；1，启动中；2，运行中；3，已过期
        if (item.runStatus === 2) {
            this.$message.warning("请先停止当前系统！");
            return ;
        }
        const msg = `您是否确认需要卸载当前“${item.appName}”，提交卸载后将自动清除当前系统下的所有数据`;
        this.$confirm(msg, '卸载', {
            confirmButtonText: '确认卸载',
            cancelButtonText: '取消',
        }).then(() => {
            // 确定
            this.uninstallCheckFlag = true;
            this.mcId = item.mcId;
        }).catch(() => {
            // 取消
        });
    }
    private closeHandleUninstallCheck(num: number) {
        this.uninstallCheckFlag = false;
        if (num === 3) {
            // 刷新一下 应用列表
            this.getMcList();
        }
    }
    // 应用 启动、停止
    private openStopMC(item: any, handleType: number) {
        // -3，已拒绝；-2，已卸载；-1，待部署；0，已停止；1，启动中；2，运行中；3，已过期
        this.$confirm(`请确定是否${handleType === 0 ? '停止' : '启动'}应用“${item.appName}”?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(() => {
            // 确定
            this.startStopMC(item, handleType);
        });
    }
    private startStopMC(item: any, handleType: number) {
        const paramData: any = {};
        paramData.mcId = item.mcId;
        paramData.userId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
        this.$httpService
        .getData(paramData, `/apiProxy/api/frontend/user/server/mc/${handleType === 0 ? 'stop' : 'start'}`)
        .then((res: any) => {
            if (handleType === 0) {
                this.$message.success('停止成功');
            } else {
                this.$message.success('启动成功');
            }
            this.getMcList();
        });
    }
    private handleRenewal(item: any) {
        this.renewalProdInfo = {
            icon: item.icon,
            name: item.appName,
            expirationTime: item.mcExpireAt,
            mcId: item.mcId,
            appId: item.goodsId,
            productCategory: 0, // 0，是应用；1，是saas；理论这里都是应用
        };
        this.renewalFlag = true;
    }
    // 关闭续费弹窗
    private renewalClose() {
        this.renewalFlag = false;
    }
    private getDBPassword(item: any) {
        const paramData: any = {};
        paramData.serverId = this.serverId;
        paramData.sdnId = item.dbId;
        paramData.userId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
        this.$httpService
        .getData(paramData, `/apiProxy/api/frontend/user/server/db/pwd`)
        .then((res: any) => {
            this.$alert(`数据库密码：   ${res.password}`, '查看数据库密码', {
                confirmButtonText: '确定',
                callback: (action) => {
                    // ...
                },
            });
        });
    }
    private openDetail(item: any) {
        this.currentRow = item;
        this.detailFlag = true;
    }
    private closeHandleAppDetail(num: number) {
        if (num === 0) {
            this.detailFlag = false;
        } else if (num === 1) {
            this.handleRenewal(this.currentRow);
        } else if (num === 2) {
            // 应用数据库续费
            this.gotoDB(1, this.currentRow.dbId);
        } else if (num === 3) {
            // 应用数据库升级
            this.gotoDB(2, this.currentRow.dbId);
        }
    }
    private handleResetPassWord() {
        const msg = `请确定是否对该服务器重置密码？`;
        this.$confirm(msg, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            // 确定
            const paramData: any = {};
            paramData.serverId = this.serverId;
            paramData.userId = this.userId !== '' ? this.userId : this.$store.state.userInfo.id;
            this.$httpService.getData(paramData, '/apiProxy/api/frontend/user/server/password/reset')
            .then((res: any) => {
                this.$message.success('密码重置成功！');
                this.serverInfo.sshPassword = res.password;
            });
        }).catch(() => {
            // 取消
        });
    }
    private handleBuyProduct(item: any) {
        const baseUrl = process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '';
        window.open(`${baseUrl}/goods/detail?goodsId=${item.goodsId}&active=2`);
    }
    private btnBackHandle() {
        if (this.paramServer.serverType === 4) {
            // 切换4.0云服务器页面
            this.activePage = PageType.ActivePageTypeMoreDevMain;
        } else {
            // 旧版云服务器页面
            this.activePage = PageType.ActivePageTypeMain;
        }
    }
}
